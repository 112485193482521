import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

function Footer() {
  const [contactData, setContactData] = useState(null);
  const [visitorCount, setVisitorCount] = useState(null);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get("https://www.krscrackers.com/customerapi/content.php");
        const { body } = response.data;
        setContactData(body.contact);
        setVisitorCount(body.visitor_count)
      } catch (error) {
        console.error("Error fetching the contact data", error);
      }
    };

    fetchContactData();
  }, []);

  // Fallback content if data is not loaded
  if (!contactData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <footer id="footer" className="footer dark-background">
        <div className="container1">
          <div className="row gy-3">
            <div className="col-lg-2 col-md-6 d-flex">
              <i className="bi bi-geo-alt icon"></i>
              <div className="address">
                <h4>Address</h4>
                <p className='contactfooter'>{contactData.address}</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex">
              <i className="bi bi-telephone icon"></i>
              <div>
                <h4>Contact</h4>
                <p className='contactfooter'>
                  <strong >Phone:</strong> <span>{contactData.phonenumber}</span><br/><br/>
                  <strong>Email:</strong> <span>{contactData.email}</span><br/><br/>
                  <strong>WhatsApp:</strong> <span>{contactData.whatsapp_number}</span>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex">
              <i className="bi bi-clock icon"></i>
              <div>
                <h4>Opening Hours</h4>
                <p className='contactfooter'>
                  {/* <strong>Mon-sun:</strong>  */}
                  <span>{contactData.timing}</span><br/><br/>
                  <h3>Visitors Count: {visitorCount.visitor_count}</h3>
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 d-flex">
              <i className="bi bi-telephone icon"></i>
              <div>
                <h4>Quick Links</h4>
                <p className='footer-quicklink'>
                <Link to= "/shippingpolicy"> <strong style={{ color: "lightgreen" }}><i className="bi bi-arrow-right"></i></strong> <span className='footlinks'>Shipping & Delivery</span><br/><br/></Link>
                  <Link to= "/terms">  <strong style={{ color: "lightgreen" }}><i className="bi bi-arrow-right"></i></strong> <span className='footlinks'>Terms & Conditions</span><br/><br/> </Link>
                 <Link to= "/privacy"> <strong style={{ color: "lightgreen" }}><i className="bi bi-arrow-right"></i></strong> <span className='footlinks'>Privacy Policy</span> </Link>
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-6">
              <h4>Follow Us</h4>
              <div className="social-links d-flex">
                {/* <Link to="/" className="twitter"><i className="bi bi-twitter-x"></i></Link> */}
                <a href="https://www.facebook.com/people/KRSCrackers/61566358776329" className="facebook" target="_blank" rel="noopener noreferrer">
             <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/krs_crackers" className="instagram" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram"></i>
            </a>                {/* <Link to="/" className="linkedin"><i className="bi bi-youtube"></i></Link> */}
              </div>
            </div>
          </div>
        </div>
        
        <div className="rules">
          <p className='rules1'>
            Please Note: The honorable Supreme Court of India has banned sales of firecrackers from 2018. We obey the order and we don't permit online
            purchase of crackers. For your convenience, we show our products on the website. We request you to add your products to the Quick
            Purchase and submit the required crackers through the enquiry button. We will contact you within 24 hrs and confirm the order
            through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with Krs Crackers.
          </p>
          
        </div>
       
      </footer>
      <div className="container12 text-center mt-4">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">Krs Crackers .</strong> <span>All Rights Reserved.</span> Developed by <Link to="https://aatheshsoft.com/" style={{ color: "white" }}> Aathesh Soft Solution</Link></p>
        </div>
    </div>
  );
}

export default Footer;
