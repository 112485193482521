import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Footer from "./Footer";
import Header from "./Header";
import { FaArrowUp } from "react-icons/fa";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import { IoSendSharp } from "react-icons/io5";
import CrackersLoader from "./CrackersLoader";

const Quick = () => {
  const [categories, setCategories] = useState([]);
  // const [carouselImages, setCarouselImages] = useState([]);
  // const [marqueeText, setMarqueeText] = useState("");
  const [counts, setCounts] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 426);
  const [searchQuery, setSearchQuery] = useState("");
  //const [isChatBoxVisible, setChatBoxVisible] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [discountvalue, setDiscountvalue] = useState();
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  const [discountTotal, setDiscountTotal] = useState();
  const [nonDiscountTotal, setNonDiscountTotal] = useState();
  const qtyCount = Object.values(counts).reduce((acc, count) => acc + count, 0);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Retrieve cart count from local storage
    const count = parseInt(localStorage.getItem("cartCount")) || 0;
    setCartCount(count);

    // Optionally, listen for changes in local storage (if using multiple tabs)
    const handleStorageChange = () => {
      const count = parseInt(localStorage.getItem("cartCount")) || 0;
      setCartCount(count);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // const toggleChatBox = () => {
  //     setChatBoxVisible(!isChatBoxVisible);
  // };

  const fetchCartItems = () => {
    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://www.krscrackers.com/customerapi/home.php"
        );
        const { body } = response.data;

        const initialCounts = body.category   
          .flatMap((category) =>
            category.product.map((product) => ({
              product_id: product.product_id,
              count:
                parseInt(localStorage.getItem(`count_${product.product_id}`)) ||
                0,
            }))
          )
          .reduce((acc, { product_id, count }) => {
            acc[product_id] = count;
            return acc;
          }, {});

        setCategories(body.category || []);
        setDiscountvalue(body.discount_value[0]?.discount_value || []);

        //setCarouselImages(body.banner || []);
        // setMarqueeText(body.scrollnew[0]?.msg || "");
        setCounts(initialCounts);

        // Fetch cart items
        fetchCartItems();
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 426);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      fetchCartItems();
    };

    window.addEventListener("storage", handleStorageChange);

    // Listen for the custom 'cartUpdated' event
    const handleCartUpdated = () => {
      fetchCartItems();
    };
    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  const updateTotals = useCallback(
    (counts) => {
      let discountTotal = 0;
      let nonDiscountTotal = 0;

      categories.forEach((category) => {
        category.product.forEach((product) => {
          const count = counts[product.product_id] || 0;
          const price = parseFloat(product.oldprice.replace(/[^\d.-]/g, ""));

          if (!isNaN(price)) {
            if (category.non_discount === "0") {
              // Apply discount
              const discountRate = Math.round(discountvalue);
              //const discountAmount = (discountTotal * discountRate) / 100;
              discountTotal += count * (price - discountAmount);
            } else {
              // No discount applied
              nonDiscountTotal += count * price;
            }
          }
        });
      });

      // Set the totals
      setTotalAmount(discountTotal + nonDiscountTotal);

      // If needed, you can set individual totals as well
      setDiscountTotal(discountTotal);
      setNonDiscountTotal(nonDiscountTotal);
    },
    [categories]
  );

  useEffect(() => {
    updateTotals(counts);
  }, [counts, updateTotals]);

  const discountRate = Math.round(discountvalue);
  const discountAmount = (discountTotal * discountRate) / 100;
  const finalAmount = discountTotal - discountAmount + nonDiscountTotal;

  const handleIncrement = (product_id) => {
    const newCounts = {
      ...counts,
      [product_id]: (counts[product_id] || 0) + 1,
    };
    setCounts(newCounts);
    saveCountsToLocalStorage(newCounts);
  };

  const handleDecrement = (product_id) => {
    const newCounts = {
      ...counts,
      [product_id]: Math.max((counts[product_id] || 0) - 1, 0),
    };
    setCounts(newCounts);
    saveCountsToLocalStorage(newCounts);
  };

  const saveCountsToLocalStorage = (counts) => {
    Object.entries(counts).forEach(([product_id, count]) => {
      localStorage.setItem(`count_${product_id}`, count);
    });

    const addedItems = categories
      .flatMap((category) =>
        category.product.map((product) => ({
          ...product,
          count: counts[product.product_id] || 0,
          category_id: category.category_id,
          is_non_discount: product.is_non_discount,
        }))
      )
      .filter((item) => item.count > 0);

    localStorage.setItem("cartItems", JSON.stringify(addedItems));

    const totalUniqueProductCount = Object.keys(counts).filter(
      (key) => counts[key] > 0
    ).length;
    localStorage.setItem("cartCount", totalUniqueProductCount);

    window.dispatchEvent(new Event("storage"));
    window.dispatchEvent(new Event("cartUpdated")); // Custom event for cart update
  };

  const handleAddClick = (product_id) => {
    handleIncrement(product_id);
  };

  const filteredCategories = categories
    .map((category) => {
      const filteredProducts = category.product.filter((product) =>
        product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return {
        ...category,
        product: filteredProducts,
      };
    })
    .filter((category) => category.product.length > 0); // Only keep categories with product

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      localStorage.setItem("cartCount", cartItems.length);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [cartItems]);

  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }

  return (
    <div className="main">
      <Header />

      {/* Search Box Section */}
      <div className="search-box-container">
        <div className="search-box">
          <Form.Control
            type="text"
            placeholder="Search Products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      {/* =================================================================================================================

                             table 1
================================================================================================================= */}
      {/* Category and Product Table Section */}
      <div className="product-table">
        <Table striped bordered hover>
          <thead
            style={{ "--bs-table-bg": "#961102", "--bs-table-color": "white" }}
          >
            <tr>
              <th className="image-column">Image</th>
              <th className="name-column">Product Name</th>
              {!isSmallScreen && <th className="price-column">Price</th>}
              {!isSmallScreen && <th className="qty-column">Qty</th>}
              {!isSmallScreen && (
                <th className="qty-per-box-column">Qty per Box</th>
              )}
              {!isSmallScreen && <th className="total-column">Total</th>}
            </tr>
          </thead>
          <tbody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category) => (
                <React.Fragment key={category.category_id}>
                  <tr className="category-header">
                    <td
                      colSpan={isSmallScreen ? 4 : 7}
                      className="category-header"
                    >
                      <h5 className="ctgyname">{category.category_name}</h5>
                    </td>
                  </tr>
                  {category.product.map((product) => (
                    <tr key={product.product_id}>
                      <td className="tab-img image-column">
                        {product.image && (
                          <img
                            src={product.image}
                            alt={product.product_name}
                            style={{
                              width: "60px",
                              height: "auto",
                              objectFit: "cover",
                            }}
                            loading="lazy"
                            onClick={() => handleImageClick(product.image)}
                          />
                        )}
                      </td>
                      <td className="product-detail-column name-column">
                        <div className="product-detail">
                          <strong className="productname">
                            {product.product_name}
                          </strong>
                          {isSmallScreen && (
                            <>
                              <div>
                                Price:{" "}
                                {parseInt(category.non_discount, 10) === 0 ? (
                                  <>
                                    <i className="bi bi-currency-rupee"></i>
                                    <del style={{ color: "red" }}>
                                      {product.oldprice
                                        ? parseFloat(
                                            product.oldprice.replace(
                                              /[^\d.-]/g,
                                              ""
                                            )
                                          ).toFixed(2)
                                        : "N/A"}
                                    </del>
                                    <nobr>
                                    <i className="bi bi-currency-rupee"></i>
                                    {product.price
                                      ? parseFloat(
                                          product.price.replace(/[^\d.-]/g, "")
                                        ).toFixed(2)
                                      : "N/A"}
                                      </nobr>
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-currency-rupee"></i>
                                    {product.oldprice
                                      ? parseFloat(
                                          product.oldprice.replace(
                                            /[^\d.-]/g,
                                            ""
                                          )
                                        ).toFixed(2)
                                      : "N/A"}
                                  </>
                                )}
                              </div>
                              {counts[product.product_id] === 0 ? (
                                <Button
                                  onClick={() =>
                                    handleAddClick(product.product_id)
                                  }
                                  className="add-btn"
                                >
                                  Add
                                </Button>
                              ) : (
                                <div className="counter-btn">
                                  <Button
                                    className="countadd"
                                    onClick={() =>
                                      handleDecrement(product.product_id)
                                    }
                                  >
                                    -
                                  </Button>
                                  <div className="counter-display">
                                    {counts[product.product_id]}
                                  </div>
                                  <Button
                                    className="countadd"
                                    onClick={() =>
                                      handleIncrement(product.product_id)
                                    }
                                  >
                                    +
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      {!isSmallScreen && (
                        <>
                          <td className="price-column">
                            {parseInt(category.non_discount, 10) === 0 ? (
                              <>
                                <i className="bi bi-currency-rupee"></i>
                                <del style={{ color: "red" }}>
                                  {product.oldprice
                                    ? parseFloat(
                                        product.oldprice.replace(/[^\d.-]/g, "")
                                      ).toFixed(2)
                                    : "N/A"}
                                </del>
                                <nobr>
                                <i className="bi bi-currency-rupee"></i>
                                {product.price
                                  ? parseFloat(
                                      product.price.replace(/[^\d.-]/g, "")
                                    ).toFixed(2)
                                  : "N/A"}
                                  </nobr>
                              </>
                            ) : (
                              <>
                              <nobr>
                                <i className="bi bi-currency-rupee"></i>
                                {product.oldprice
                                  ? parseFloat(
                                      product.oldprice.replace(/[^\d.-]/g, "")
                                    ).toFixed(2)
                                  : "N/A"}
                                  </nobr>
                              </>
                            )}
                          </td>

                          <td className="qty-column">
                            {counts[product.product_id] === 0 ? (
                              <Button
                                onClick={() =>
                                  handleAddClick(product.product_id)
                                }
                                className="add-btn"
                              >
                                Add
                              </Button>
                            ) : (
                              <div className="counter-btn">
                                <Button
                                  className="countadd"
                                  onClick={() =>
                                    handleDecrement(product.product_id)
                                  }
                                >
                                  -
                                </Button>
                                <div className="counter-display">
                                  {counts[product.product_id]}
                                </div>
                                <Button
                                  className="countadd"
                                  onClick={() =>
                                    handleIncrement(product.product_id)
                                  }
                                >
                                  +
                                </Button>
                              </div>
                            )}
                          </td>
                          <td className="qty-per-box-column">
                            {product.qty_per_box || "N/A"}
                          </td>
                          <td className="total-column">
                            {parseInt(category.non_discount, 10) === 0 ? (
                              <>
                                <i className="bi bi-currency-rupee"></i>
                                {Math.round(
                                  counts[product.product_id] *
                                    (parseFloat(
                                      product.price.replace(/[^\d.-]/g, "")
                                    ) || 0) *
                                    100
                                ) / 100}
                              </>
                            ) : (
                              <>
                                <i className="bi bi-currency-rupee"></i>
                                {Math.round(
                                  counts[product.product_id] *
                                    (parseFloat(
                                      product.oldprice.replace(/[^\d.-]/g, "")
                                    ) || 0) *
                                    100
                                ) / 100}
                              </>
                            )}
                          </td>
                        </>
                      )}
                      {/* {!isSmallScreen && (
                        <td>
                          {counts[product.product_id] === 0 ? (
                            <Button
                              onClick={() => handleAddClick(product.product_id)}
                              className="add-btn"
                            >
                              Add
                            </Button>
                          ) : (
                            <div className="counter-btn">
                              <Button
                                onClick={() => handleDecrement(product.product_id)}
                              >
                                -
                              </Button>
                              <div className="counter-display">
                                {counts[product.product_id]}
                              </div>
                              <Button
                                onClick={() => handleIncrement(product.product_id)}
                              >
                                +
                              </Button>
                            </div>
                          )}
                        </td>
                      )} */}
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <p>No products found</p>
            )}
          </tbody>
        </Table>

        {/* <h6 style={{color:"red",margin:"10px",display:"flex",justifyContent:"center"}}>Continue To Purchase Click The  View Cart Icon</h6> */}
      </div>

      {/* Footer */}
      <Footer />

      {/* Order Summary Section */}
      <div className="order">
        <Link to="/cart">
          <Container>
            <Row lg={3} className="estimate">
              {/* <Col xs={3} sm={6} md={4} lg={3} className="col1">
                Order Total
                <hr />
                <div>
                  <i className="bi bi-currency-rupee"></i>
                  {totalAmount.toFixed(2)}
                </div>
              </Col> */}
            {/*   <Col xs={3} sm={6} md={4} lg={3} className="col2">
                Discount({discountRate}%)
                <hr />
                <div>
                  <i className="bi bi-currency-rupee"></i>
                  {discountAmount.toFixed(2)}
                </div>
              </Col> */}
              <Col xs={3} sm={6} md={4} lg={3} className="col3">
                Total Amount
                <Button style={{marginLeft:"20px",backgroundColor:"red",border:"none"}}>Checkout</Button>
                
                <hr />
                <div>
                  <i className="bi bi-currency-rupee"></i>
                  {finalAmount.toFixed(2)}
                </div>
              </Col>
            </Row>
          </Container>
        </Link>
      </div>
      <div className="minifooter">
        <div className="rate">
         {/*  <p> Discount Amt ({discountRate}%) :</p>
          <p>
            <i className="bi bi-currency-rupee"></i>
            {discountAmount.toFixed(2)}
          </p> */}
          <div>
            <span style={{ fontSize: "1rem" }}>
              <p> Total Qty : {qtyCount} | Total Price :</p>
              <p>
                <i className="bi bi-currency-rupee"></i>{" "}
                {finalAmount.toFixed(2)}
              </p>
            </span>
            {/* <span style={{ fontSize: "0.7rem" }}>Home</span> 
          <span style={{ fontSize: "0.7rem" }}>Terms & Conditions</span>
          <span style={{ fontSize: "0.7rem" }}>Privacy Policy</span> */}
          </div>
        </div>

        {/* <div >
          <Link to="/cart">
          
          <p style={{color:"white"}}> 
            View Cart <i class="bi bi-bag"></i>
          </p>
          </Link>
        </div> */}
      </div>

      <div className="minifooter1">
        <div className="minimenu">
          <Link to="/">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-house-door"
                style={{ marginLeft: "12px", fontSize: "1.4rem" }}
              ></i>
              Home
            </div>
          </Link>

          <Link to="/quick">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-bag"
                style={{ marginLeft: "25px", fontSize: "1.4rem" }}
              ></i>
              Quick Buy
            </div>
          </Link>

          <Link to="/cart">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                position: "relative",
              }}
            >
              <i
                className="bi bi-cart"
                style={{
                  marginLeft: "25px",
                  fontSize: "1.4rem",
                  position: "relative",
                }}
              ></i>
              View Cart
              {cartCount > 0 && (
                <span
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-2px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "0.7rem",
                    color: "#a01717",
                  }}
                >
                  {cartCount}
                </span>
              )}
            </div>
          </Link>

          <Link to="/contact">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-telephone"
                style={{ marginLeft: "12px", fontSize: "1.4rem" }}
              ></i>
              Contact
            </div>
          </Link>
        </div>
      </div>

      {/* Floating WhatsApp Icon */}
      {/* WhatsApp Icon */}
      {/* <div className="floating-whatsapp-container">
        <div className="floating-whatsapp" onClick={toggleChatBox}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </div>
        {isChatBoxVisible && (
          <div className="chat-box">
            <div className="wats-header">
              <p>Sri Poorvikaa Fireworks</p>
              <button className="close-button" onClick={toggleChatBox}>
                X
              </button>
            </div>
            <div className="wats-content">
              <p>Welcome To Sri Poorvikaa Fireworks</p>
            </div>
            <div className="chat-content">
              <textarea placeholder="Type your message here..."></textarea>
              <button onClick={() => alert("Message sent!")}>
                <IoSendSharp />
              </button>
            </div>
          </div>
        )}
      </div>  */}

      {/* Modal for Image */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <>
        {showButton && (
          <button className="scroll-to-top1" onClick={scrollToTop}>
            <FaArrowUp />
          </button>
        )}
      </>
    </div>
  );
};

export default Quick;
