import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import axios from "axios";
import image2 from "../Assets/page_bg.jpg";
import Footer from "./Footer";
import Header from "./Header";
import image1 from "../Assets/krshome_img.jpg";
import { Link } from "react-router-dom";
import CrackersLoader from './CrackersLoader';


function About() {
  const [aboutData, setAboutData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to manage loading


  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get("https://www.krscrackers.com/customerapi/content.php");
        const { body } = response.data;
        setAboutData(body.about);
        setContactData(body.contact);
      } catch (error) {
        console.error("Error fetching the about data", error);
      }finally {
              setIsLoading(false); // Stop loading after data is fetched
            }
    };

    fetchAboutData();
  }, []);

  // Fallback content if data is not loaded
  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }

  

  return (
    <div>
      <div className="main-homepage">
        <Header />

        {/* Parallax Section */}
        <Parallax
          bgImage={image2}
          bgImageAlt="the cat"
          strength={500}
          style={{ minHeight: "100px" }}
          
        >
          <div style={{ height: "230px" }}>
            <h1
              style={{
                color: "white",
                paddingTop: "100px",
                textAlign: "center",
              }}
            >
              About
            </h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              <Link to="/" style={{color:"white"}}>
              Home</Link> <i className="bi bi-chevron-right"></i> About Us
            </p>
          </div>
        </Parallax>

        <div className="about-section">
          {/* Top Image */}
          <div className="topimg">
            <img src={image1} alt="" />
          </div>
          {/* Content aligned to the right */}
          <div className="content-text">
            <h5 style={{fontWeight:"bold"}}>Our Shop Details</h5>
            <br />
            <h3 style={{ letterSpacing: "2px", color: "darkblue" }}>
             KRS CRACKERS
            </h3>
            <h4>
              RETAIL SUPERIOR FANCY CRACKERS{" "}
              {/* <span style={{ color: "darkred" }}>SIVAKASI</span> */}
            </h4>
            <br />
            {/* <p style={{ fontFamily: " Arial, Helvetica, sans-serif" }}>
              {aboutData.description}
            </p> */}

            <p
              style={{ fontFamily: " Arial, Helvetica, sans-serif" }}
              dangerouslySetInnerHTML={{ __html: aboutData.value }}
            />
            <br />
            <hr />
            <div>
              <h3>
                Call To Ask Any Enquiry{" "}
                <span style={{ color: "red" }}> {contactData.whatsapp_number}</span>
              </h3>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
      <div className="minifooter1">
      <div className="minimenu">
          
      <Link to="/">
            <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-house-door" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Home
             </div>
             </Link>
         
             <Link to="/about">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-journal-text officon" style={{marginLeft:"20px",fontSize:"1.4rem",color:"white"}}></i>
             About Us
             </div>
             </Link>

             
          <Link to="/quick">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-bag" style={{marginLeft:"25px",fontSize:"1.4rem"}}></i>
             Quick Buy
             </div>
             </Link>
             
            
         
          <Link to="/contact">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-telephone" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Contact
             </div>
             </Link>
          
        </div>
      </div>
    </div>
  );
}

export default About;
