import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
// import Logo from "../Assets/saibaba-logo.png";
import Button from "react-bootstrap/Button";
import Footer from "./Footer";
import Header from "./Header";
import Scroll from "./Scroll";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { IoSendSharp } from "react-icons/io5";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import axios from "axios"; // Import axios
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import CrackersLoader from "./CrackersLoader";

function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [discountvalue, setDiscountvalue] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [formErrors, setFormErrors] = useState({});
  //const [isChatBoxVisible, setChatBoxVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  const [minimumOrder, setMinimumOrder] = useState(null);
  const [storeClosed, setStoreClosed] = useState(null);
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 426);

  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

 // Static coupon codes
const validCoupons = ["RAJIDL", "KRSSVK", "BGNTRY"];

// Handle coupon code input change
const handleCouponChange = (e) => {
  setCouponCode(e.target.value);
  setErrorMessage("");
};

// Validate coupon and apply discount
const applyCoupon = () => {
  // Regular expression to check for a 6-letter code
  const couponRegex = /^[a-zA-Z0-9]{6}$/;

  // Check if the coupon code is valid
  if (validCoupons.includes(couponCode)) {
    setIsCouponApplied(true);
    setErrorMessage(""); // Clear any previous error
    // Apply your discount logic here
  } else if (couponCode === "" || couponRegex.test(couponCode)) {
    setErrorMessage("Invalid coupon code. Please enter a valid coupon code.");
    setIsCouponApplied(false);
  } else {
    setErrorMessage("Coupon code must be exactly 6 letters.");
    setIsCouponApplied(false);
  }
};


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 426);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  }, []);

  // const toggleChatBox = () => {
  //     setChatBoxVisible(!isChatBoxVisible);
  // };

  const handleQuantityChange = (product_id, change) => {
    const updatedItems = cartItems.map((item) =>
      item.product_id === product_id
        ? { ...item, count: Math.max(item.count + change, 1) }
        : item
    );

    setCartItems(updatedItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedItems));
    const updatedCounts = updatedItems.reduce((acc, item) => {
      acc[item.product_id] = item.count;
      return acc;
    }, {});
    saveCountsToLocalStorage(updatedCounts);
  };

  const handleDelete = (product_id) => {
    // Filter out the product with the specified product_id
    const updatedItems = cartItems.filter(
      (item) => item.product_id !== product_id
    );

    // Update the cartItems in localStorage
    localStorage.setItem("cartItems", JSON.stringify(updatedItems));

    // Update the cartItems state
    setCartItems(updatedItems);

    // Optionally, remove the count from localStorage if you're using it
    localStorage.removeItem(`count_${product_id}`);

    // Update cart count in localStorage if needed
    const totalUniqueProductCount = updatedItems.length;
    localStorage.setItem("cartCount", totalUniqueProductCount);

    // Save the counts of remaining items to localStorage
    saveCountsToLocalStorage(updatedItems);
  };

  const calculateTotal = (price, count) => {
    if (!price) {
      return "0.00"; // If price is undefined or null, return "0.00"
    }

    const sanitizedPrice = parseFloat(price.replace(/[^\d.-]/g, ""));

    if (isNaN(sanitizedPrice)) {
      return "0.00"; // If parsing results in NaN, return "0.00"
    }

    return (count * sanitizedPrice).toFixed(2);
  };

  const getSubtotal = () => {
    return cartItems
      .reduce((total, item) => {
        return total + parseFloat(calculateTotal(item.oldprice, item.count));
      }, 0)
      .toFixed(2);
  };

  const getDiscountedTotal = () => {
    const subtotal = cartItems.reduce((total, item) => {
      if (item.is_non_discount === "0") {
        // Apply discount only if isnondiscount is "0"
        const discount = Math.round(discountvalue);
        const itemTotal = parseFloat(calculateTotal(item.oldprice, item.count));
        const discountedItemTotal = (itemTotal * discount) / 100;
        return total + discountedItemTotal;
      } else {
        // No discount applied
        return total;
      }
    }, 0);

    return subtotal.toFixed(2);
  };

  const getOrderTotal = () => {
    const subtotal = parseFloat(getSubtotal());
    const discountTotal = parseFloat(getDiscountedTotal());

    return (subtotal - discountTotal).toFixed(2);
  };

  // Function to calculate the grand total with coupon discount
  const grandTotal = () => {
    let total = Math.round(parseFloat(getOrderTotal()));

    // Apply 5% discount only if the correct coupon code is applied
    if (isCouponApplied) {
      total = total - total * 0.05; // Apply 5% discount
    }

    return Math.round(total); // Return grand total (with or without discount)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber" && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
  
    // Loop through each form field except email for validation
    Object.keys(formData).forEach((key) => {
      if (key !== "email" && !formData[key]) {
        errors[key] = "This field is required.";
      }
    });
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleOrderSuccess = () => {
    // Clear local storage
    localStorage.removeItem("cartItems");
    localStorage.removeItem("cartCount");

    // Optionally clear individual product counts if needed
    const productKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("count_")
    );
    productKeys.forEach((key) => localStorage.removeItem(key));

    // Notify home page about the change
    window.dispatchEvent(new Event("cartUpdated")); // Trigger custom event to update cart state

    navigate("/thanks");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://www.krscrackers.com/customerapi/home.php"
        );
        const { body } = response.data;

        const initialCounts = body.category
          .flatMap((category) =>
            category.product.map((product) => ({
              product_id: product.product_id,
              count:
                parseInt(localStorage.getItem(`count_${product.product_id}`)) ||
                0,
            }))
          )
          .reduce((acc, { product_id, count }) => {
            acc[product_id] = count;
            return acc;
          }, {});

        // Set discount value
        setDiscountvalue(body.discount_value[0]?.discount_value || []);

        // Set minimum order and store closed values
        const minimumOrderStoreData = body.minimum_order_store_closed[0];
        setMinimumOrder(minimumOrderStoreData.minimum_order);

        console.log("minimum order" + minimumOrderStoreData.minimum_order );

        setStoreClosed(minimumOrderStoreData.store_closed);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const orderData = {
        name: formData.firstName,
        email: formData.email,
        phonenumber: formData.mobileNumber,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        pincode: formData.pincode,
        subtotal: getSubtotal(),
        discount: getDiscountedTotal(),
        total: grandTotal(),
        coupon_code: isCouponApplied ? couponCode : "", // Send coupon code if applied
        product: cartItems.map((item) => ({
          product_id: item.product_id,
          category_id: item.category_id || "",
          product_name: item.product_name,
          unit_price: item.price,
          quantity: item.count,
          final_price: calculateTotal(item.price, item.count),
        })),
      };

      try {
        const response = await axios.post(
          "https://www.krscrackers.com/customerapi/order.php",
          orderData
        );
        console.log(response.data);
        alert("Order placed successfully!");

        // Call handleOrderSuccess after successful order
        handleOrderSuccess();
      } catch (error) {
        console.error("There was an error placing the order!", error);
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  const saveCountsToLocalStorage = (data) => {
    // Determine if the data is in the format of an array (items) or an object (counts)
    if (Array.isArray(data)) {
      // Handle array of items
      data.forEach((item) => {
        localStorage.setItem(`count_${item.product_id}`, item.count);
      });
    } else {
      // Handle object of counts
      Object.entries(data).forEach(([product_id, count]) => {
        localStorage.setItem(`count_${product_id}`, count);
      });
    }

    // Dispatch a storage event to notify other tabs or components of the update
    window.dispatchEvent(new Event("storage"));
  };

  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }

  return (
    <div className="main1">
      <Header />
      {/* <div className="marquee-container">
        <div className="marquee">
          <p style={{ color: "red" }}>
            Welcome to Crackers World! Enjoy our Diwali specials and offers!
            Order minimum amount RS.3000. Your order generates the minimum
            amount.
          </p>
        </div>
      </div> */}
      <div className="product-table">
        <h5
          style={{
            display: "flex",
            justifyContent: "center",
            color: "red",
            marginTop: "20px",
          }}
        >
          Display Your Shopping Cart
        </h5>
        <Table striped bordered hover xs={12}>
          <thead
            style={{ "--bs-table-bg": "#081b31", "--bs-table-color": "white" }}
          >
            <tr>
              <th style={{ display: "flex", justifyContent: "center" }}>
                Image
              </th>
              <th>Product Name</th>
              {!isSmallScreen && <th>Price</th>}
              {!isSmallScreen && <th>Qty</th>}
              <th>Total</th>
              <th>Del</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item) => {
              // Determine if the category is non-discount
              const isNonDiscount = parseInt(item.is_non_discount, 10) === 1;

              return (
                <tr key={item.product_id}>
                  <td className="tab-img">
                    <img
                      src={item.image}
                      alt={item.product_name}
                      loading="lazy"
                      className="tab-img"
                    />
                  </td>
                  <td>
                    <strong className="productname">
                      {item.product_name}{" "}
                    </strong>

                    {isSmallScreen && (
                      <>
                        <div style={{ fontSize: "14px" }}>
                          Price:{" "}
                          {isNonDiscount ? (
                            <>
                              <i className="bi bi-currency-rupee"></i>
                              {item.oldprice}
                            </>
                          ) : (
                            <>
                              <del style={{ color: "red" }}>
                                <i className="bi bi-currency-rupee"></i>
                                {item.oldprice}
                              </del>
                              &nbsp;
                              <nobr>
                                <i className="bi bi-currency-rupee"></i>
                                {item.price}
                              </nobr>
                            </>
                          )}
                        </div>

                        <div className="quantity-controls">
                          <Button
                            className="countadd"
                            variant="outline-secondary"
                            onClick={() =>
                              handleQuantityChange(item.product_id, -1)
                            }
                          >
                            -
                          </Button>
                          <span style={{ margin: "0 10px" }}>{item.count}</span>
                          <Button
                            className="countadd"
                            variant="outline-secondary"
                            onClick={() =>
                              handleQuantityChange(item.product_id, 1)
                            }
                          >
                            +
                          </Button>
                        </div>
                      </>
                    )}
                  </td>
                  {console.log(".........." + item.oldprice)}
                  {!isSmallScreen && (
                    <>
                      <td>
                        {isNonDiscount ? (
                          <>
                            <i className="bi bi-currency-rupee"></i>
                            {item.oldprice}
                          </>
                        ) : (
                          <>
                            <del style={{ color: "red" }}>
                              <i className="bi bi-currency-rupee"></i>
                              {item.oldprice}
                            </del>
                            &nbsp;
                            <nobr>
                              <i className="bi bi-currency-rupee"></i>
                              {item.price}
                            </nobr>
                          </>
                        )}
                      </td>
                      <td>
                        <Button
                          className="countadd"
                          variant="outline-secondary"
                          onClick={() =>
                            handleQuantityChange(item.product_id, -1)
                          }
                        >
                          -
                        </Button>
                        <span style={{ margin: "0 10px" }}>{item.count}</span>
                        <Button
                          className="countadd"
                          variant="outline-secondary"
                          onClick={() =>
                            handleQuantityChange(item.product_id, 1)
                          }
                        >
                          +
                        </Button>
                      </td>
                    </>
                  )}
                  <td>
                    <nobr>
                      <i className="bi bi-currency-rupee"></i>
                      {calculateTotal(
                        isNonDiscount ? item.oldprice : item.price,
                        item.count
                      )}
                    </nobr>
                  </td>
                  <td>
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "red",
                        border: "none",
                      }}
                      onClick={() => handleDelete(item.product_id)}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Card className="order-form">
          <Card.Body>
            <h5 style={{ fontWeight: "bold" }}>Customer Details</h5>
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={formData.firstName}
                        onChange={handleChange}
                        isInvalid={!!formErrors.firstName}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.firstName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="tel"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        isInvalid={!!formErrors.mobileNumber}
                        maxLength={10} // Limits input to 10 digits
                        pattern="[0-9]*" // Allows only numeric input
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.mobileNumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!formErrors.email}
                        
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="address"
                        placeholder="Address"
                        rows={3}
                        value={formData.address}
                        onChange={handleChange}
                        isInvalid={!!formErrors.address}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.address}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleChange}
                        isInvalid={!!formErrors.city}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.city}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                        isInvalid={!!formErrors.state}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.state}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        placeholder="Pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        isInvalid={!!formErrors.pincode}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.pincode}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <h5 style={{ fontWeight: "bold" }}>Total</h5>

                  {/* Coupon Code Input */}
                  <div style={{ marginBottom: "15px" }}>
                    <div>
                      <p>
                        Enter Coupon Code Get Extra 5% Discount From Estimate Total Amount. 
                      </p>
                    </div>
                    <label htmlFor="coupon"></label>
                    <input
                      type="text"
                      id="coupon"
                      placeholder="Enter 6-digit coupon code"
                      value={couponCode}
                      onChange={handleCouponChange}
                      maxLength={6}
                      style={{ marginRight: "10px", padding: "5px" }}
                    />
                    <Button variant="success" onClick={applyCoupon}>
                      Apply
                    </Button>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                  </div>
                   <div>
                    <p  style={{color:"red"}}>(Use Capital Letters Only)</p>
                   </div>
                  <Table striped bordered hover>
                    <tbody>
                      {/* <tr>
                        <td style={{ fontWeight: "bold", textAlign: "left" }}>
                          Sub Total
                        </td>
                        <td style={{ fontWeight: "bold", textAlign: "left" }}>
                          <i className="bi bi-currency-rupee"></i>
                          {getSubtotal()}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          Discount {Math.round(discountvalue)}%
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <i className="bi bi-currency-rupee"></i>
                          {getDiscountedTotal()}
                        </td>
                      </tr> */}
                      <tr>
                        <td style={{ fontWeight: "bold", textAlign: "left" }}>
                          Estimate Total
                        </td>
                        <td style={{ fontWeight: "bold", textAlign: "left" }}>
                          <i className="bi bi-currency-rupee"></i>
                          {grandTotal()}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {storeClosed === "1" ? (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Store is currently closed.
                    </p>
                  ) : (
                    <>
  {parseFloat(grandTotal()) >= minimumOrder ? ( // Use minimumOrder here

    <Button
      variant="danger"
      type="submit"
      disabled={loading}
    >
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        "Order Now"
      )}
    </Button>
  ) : (
    <h6 style={{ color: "orangered" }}>
      Minimum order amount{" "}
      <i className="bi bi-currency-rupee"></i>
      {minimumOrder}. Your order will need to reach the minimum amount.
    </h6>
  )}
</>

                  )}
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>

      <div className="minifooter1">
        <div className="minimenu">
          <Link to="/">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-house-door"
                style={{ marginLeft: "12px", fontSize: "1.4rem" }}
              ></i>
              Home
            </div>
          </Link>

          <Link to="/about">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-journal-text officon"
                style={{
                  marginLeft: "20px",
                  fontSize: "1.4rem",
                  color: "white",
                }}
              ></i>
              About Us
            </div>
          </Link>

          <Link to="/quick">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-bag"
                style={{ marginLeft: "25px", fontSize: "1.4rem" }}
              ></i>
              Quick Buy
            </div>
          </Link>

          <Link to="/contact">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <i
                class="bi bi-telephone"
                style={{ marginLeft: "12px", fontSize: "1.4rem" }}
              ></i>
              Contact
            </div>
          </Link>
        </div>
      </div>

      <Footer />
      <Scroll />
    </div>
  );
}

export default Cart;
