import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PopupNotification = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null); // Store the current popup data
  const [orders, setOrders] = useState([]); // Store all orders
  const [shownOrders, setShownOrders] = useState([]); // Track displayed orders

  // Function to fetch data from the API
  useEffect(() => {
    const fetchPopupData = async () => {
      try {
        console.log('Fetching data...');
        const response = await axios.get('https://www.krscrackers.com/customerapi/order_pop_up.php');
        console.log('API response:', response.data);

        if (response.data.head.code === 200) {
          const fetchedOrders = response.data.body.order_popup;
          setOrders(fetchedOrders);
          console.log('Fetched orders:', fetchedOrders);

          // Start showing the first popup immediately if data is available
          if (fetchedOrders.length > 0) {
            showRandomOrder(fetchedOrders);
          }
        }
      } catch (error) {
        console.error('Error fetching popup data:', error);
      }
    };

    fetchPopupData();
  }, []);

  // Function to show a random order from the remaining orders
  const showRandomOrder = (availableOrders) => {
    // Filter out orders that have already been displayed
    const remainingOrders = availableOrders.filter(order => !shownOrders.includes(order));

    // If no remaining orders are left, reset the shownOrders array or stop
    if (remainingOrders.length === 0) {
      console.log('All orders have been displayed.');
      // You can reset here if you want to cycle orders again:
      // setShownOrders([]);
      // showRandomOrder(availableOrders);
      return;
    }

    // Randomly pick a new order that hasn't been shown
    const randomOrder = remainingOrders[Math.floor(Math.random() * remainingOrders.length)];
    setPopupData(randomOrder);
    setShowPopup(true);
    setShownOrders([...shownOrders, randomOrder]); // Add this order to the shown list
    console.log('Popup displayed with data:', randomOrder);

    // Hide the popup after 10 seconds
    setTimeout(() => closePopup(availableOrders), 10000);
  };

  // Function to close the current popup and show the next order
  const closePopup = (availableOrders) => {
    setShowPopup(false);

   // Show the next random order after a brief delay
setTimeout(() => {
  showRandomOrder(availableOrders);
}, 180000); // 3-minute delay before showing the next popup
}

  return (
    <>
      {showPopup && popupData && (
        <div className="popup-notification">
          <div className="popup-content">
            <p><strong>{popupData.name}</strong> from {popupData.city} just purchased products for Rs. {popupData.price}</p>
            <p>{popupData.timing}</p>
            <button className="popup-dismiss" onClick={() => closePopup(orders)}>
              Don't miss the discount
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNotification;
 