import React, { useEffect } from 'react';
import "./Stats.css";
import PureCounter from "@srexi/purecounterjs";
import { Parallax } from "react-parallax";
import image4 from "../Assets/krs_banner.jpg";
import Animation from "./Animation"


function Stats() {

    useEffect(() => {
        new PureCounter();
      }, []);

  return (
    <div className='main1234'>
       <Parallax
      bgImage={image4}
      bgImageAlt="the cat"
      strength={300}
      style={{ minHeight: "300px" }}
    >
      <div id="stats-counter" className="stats-counter section">
        <div className="container section-title " data-aos="fade-up">
        
          {/* <p style={{marginLeft:"365px"}}>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
        </div>

        <div className="container123" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 ">
              <div className="stats-item d-flex align-items-center w-100 h-100">
              <i class="bi bi-layout-wtf color-blue flex-shrink-0 sticon"></i>
                <div>
                <div style={{display:"flex",alignItems:"center"}}>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="250"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <i class="bi bi-plus"></i>
                  </div>
                  <p>No Of Products</p>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100">
              <i class="bi bi-person  color-orange flex-shrink-0 sticon"></i>
                <div>
                <div style={{display:"flex",alignItems:"center"}}>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="5000 "
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <i class="bi bi-plus"></i>
                   </div>
                  <p>Happy Customers</p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-4 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100">
              <i className="bi bi-journal-richtext color-orange flex-shrink-0 sticon"></i>
                <div>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="25"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>Year Of Experience</p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-3 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100">
                <i className="bi bi-people color-pink flex-shrink-0 sticon"></i>
                <div>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="345"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>Hard Workers</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Animation />
      </Parallax>
      
    </div>
  )
}

export default Stats